.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.about-me {
  max-width: 85%;
}

.App-link {
  color: #61dafb;
}

.social li {
  display: inline;
  text-decoration: none;
  margin: 0 10px 0 10px;
}

.fa {
  font-size: 48px;
  color: white;
}
